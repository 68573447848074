import moment from "moment";
import React, { useState, useEffect, Fragment } from "react";
import { X } from "react-feather";
import client from "../../../Api/HTTPClient";
import api from "../../../constant/apilist";
import { Button, Modal } from "reactstrap";
import { toast } from "react-toastify";

function GenerateInvoiceModal(props) {
    const {toggleInvoiceModal,isInvoiceModalOpen,invoiceDetails,getEnquiry}=props
    const [finalSpendAmount,setFinalSpendAmount]=useState(invoiceDetails?.invoice_details[0]?.final_spend_amount?invoiceDetails?.invoice_details[0]?.final_spend_amount:'')
    const [linkyCommission,setLinkyCommission]=useState(invoiceDetails?.invoice_details[0]?.linky_commission?invoiceDetails?.invoice_details[0]?.linky_commission:'')
    const [calculateCommission,setCalculateCommission]=useState(invoiceDetails?.invoice_details[0]?.linky_commission?true:false)

    useEffect(()=>{
        setCalculateCommission(false)
        setFinalSpendAmount(invoiceDetails?.invoice_details[0]?.final_spend_amount?invoiceDetails?.invoice_details[0]?.final_spend_amount:'')
        setLinkyCommission(invoiceDetails?.invoice_details[0]?.linky_commission?invoiceDetails?.invoice_details[0]?.linky_commission:'')
    },[isInvoiceModalOpen,invoiceDetails])
    const handleSubmit=(e)=>{
e.preventDefault()

let postData={
    _id:invoiceDetails?.invoice_details[0]?._id,
    merchant_id:invoiceDetails?.invoice_details[0]?.merchant_id,
    status:1,
    final_spend_amount: Number(finalSpendAmount)?Number(finalSpendAmount):0,
    linky_commission: Number(linkyCommission)?Number(linkyCommission):0,
    invoice_total: Number(Number(finalSpendAmount)+Number(linkyCommission)),
}




client.post(api.enquiry_invoice, postData, async function (error, response) {
    console.log(response,error)

    if (!error) {

        if (!response.error) {
            
            getEnquiry()
            toggleInvoiceModal()
        } 
        else {
            toast.error("Internal error occured. Please contact support");
        }

    } else {
        toast.error("Internal error occured. Please contact support");
    }

});
    }

   

    
    console.log(invoiceDetails)
    return ( 
        <Modal isOpen={isInvoiceModalOpen} centered toggle={toggleInvoiceModal} className="invoice-modal">
            <div className="p-3 " style={{background:'#f1f1f1'}}>
                <div className="d-flex justify-content-between">
                    <h5 style={{maxWidth:"70%",fontWeight:600}}>Review below details before generating invoice:</h5>
                    <X onClick={toggleInvoiceModal}/>
                </div>
                <div className="mt-2">
                    <h6><span style={{fontWeight:600}}>Tax Invoice No.: </span> <span>LKY - {invoiceDetails?.invoice_details[0]?.invoice_id}</span></h6>
                    <h6><span style={{fontWeight:600}}>Invoice Date: </span> <span>{moment(invoiceDetails?.invoice_details[0]?.invoice_upload_date).format('DD-MM-YYYY')}</span></h6>
                    <h6><span style={{fontWeight:600}}>Event Name: </span> <span>{invoiceDetails?.eventType}</span></h6>
                    <h6><span style={{fontWeight:600}}>Event ID: </span> <span>#{invoiceDetails?.unique_id}</span></h6>
                </div>

            </div>

            <form className="px-3 py-4 my-1" onSubmit={handleSubmit}>
                <h6 className="mb-2" style={{fontWeight:500}}>Enter Final Spend amount</h6>
                <div className="d-flex " style={{gap:'12px'}}>
                    <input type="number" name="final_spend_amount" required className='form-control' placeholder="Enter amount" style={{maxWidth:'50%'}} onChange={(e)=>{setFinalSpendAmount(e.target.value)}} value={finalSpendAmount}/>
                   <Button color="primary"  style={{paddingTop:'.6rem',paddingBottom:'.6rem',}} onClick={()=>{setCalculateCommission(true)}}>Calculate Commission</Button>
                </div>
                {calculateCommission &&
                    <>
                    
                <div className="d-flex mt-3 align-items-center" style={{gap:'12px'}}>
                    <input type="number" name="final_spend_amount" required className='form-control' placeholder="0.00" style={{maxWidth:'50%'}} onChange={(e)=>{setLinkyCommission(e.target.value)}} value={linkyCommission}/>
                   <span style={{fontWeight:'600'}}>= <span className="text-success" style={{marginLeft:'.5rem',display:'inline-block'}}>Linky Commission</span></span>
                </div>

                <h4 style={{fontWeight:600,fontSize:'1.1rem',marginTop:'1.3rem',marginBottom:'1.3rem'}}>Total Receivable: {parseFloat(Number(finalSpendAmount)+Number(linkyCommission)).toFixed(2)}</h4>

                <div className="d-flex justify-content-between" >
                <Button color="gray" type="button" onClick={(e)=>{toggleInvoiceModal()
e.target.blur()
                }} className="generate_invoice" style={{width:'48%',paddingTop:'.6rem',paddingBottom:'.6rem'}}>Cancel</Button>
                <Button color="primary" type="submit" onClick={(e)=>{e.target.blur()}} className="generate_invoice"  style={{width:'48%',paddingTop:'.6rem',paddingBottom:'.6rem'}}>{
                    invoiceDetails?.invoice_details[0]?.final_spend_amount?'Update':'Save as Draft'} </Button>
                </div>
                </>
                }
            </form>

        </Modal>
     );
}

export default GenerateInvoiceModal;