import React, { useState, useEffect, Fragment } from "react";
import client from "./../Api/HTTPClient";
import api from "./../constant/apilist";
import Breadcrumb from "../components/common/breadcrumb";
import { TabList, Tabs } from "react-tabs";
import Dropdown from 'react-bootstrap/Dropdown';
import { toast } from "react-toastify";
import PaginationComponent from "react-reactstrap-pagination";
import { DisappearedLoading } from "react-loadingg";
import { Button } from "reactstrap";
import GenerateInvoiceModal from "../components/ui-elements/CustomModal/GenerateInvoiceModal";
import { CheckCircle, DescriptionOutlined, ThumbDown } from "@material-ui/icons";
import { Link } from 'react-router-dom';


function FunctionBookings() {

    const [bookings_record,set_bookings_record]=useState({
        country:[],
        merchantByCountry:[],
        filter_record:[],
		defaultCountryCode: localStorage.getItem("sessionCountryCode"),
		countryTabIndex:0,
		searchValue: null,
		access_level: localStorage.getItem("accessLevel"),
		activePage:1,
		pageSize:15
    })



	const [invoiceDetails,setInvoiceDetails]=useState(null)

	const [isInvoiceModalOpen,setInvoiceModalOpen]=useState(false)

	const toggleInvoiceModal=()=>{
		setInvoiceModalOpen(!isInvoiceModalOpen)

	}

    const moveElement=(array)=> {

		let arrangeCountry = [...array];

		let australia = arrangeCountry.map((e) => e.country_code).indexOf('AU');
		if (australia != -1) {
			arrangeCountry.splice(0, 0, arrangeCountry.splice(australia, 1)[0]);
		}

		let japanIdx = arrangeCountry.map((e) => e.country_code).indexOf('JP');
		if (japanIdx != -1) {
			arrangeCountry.splice(1, 0, arrangeCountry.splice(japanIdx, 1)[0]);
		}

		let thailand = arrangeCountry.map((e) => e.country_code).indexOf('TH');
		if (thailand != -1) {
			arrangeCountry.splice(2, 0, arrangeCountry.splice(thailand, 1)[0]);
		}

		let unitedState = arrangeCountry.map((e) => e.country_code).indexOf('US');
		if (unitedState != -1) {
			arrangeCountry.splice(3, 0, arrangeCountry.splice(unitedState, 1)[0]);
		}

		let india = arrangeCountry.map((e) => e.country_code).indexOf('IN');
		if (india != -1) {
			arrangeCountry.splice(4, 0, arrangeCountry.splice(india, 1)[0]);
		}

		return arrangeCountry;
	}


	const changeCountryTab = async (data) => {

		
		set_bookings_record(prev=>
			{
				return ({...prev,defaultCountryCode: data})
	})

		

	}

	const handlePageChange = (pageNumber) => {
        

		set_bookings_record(prev=>
			{
				return ({...prev,activePage: pageNumber})
	})
    };

	console.log(bookings_record)

    const getEnquiry = async (getCountryCode) => {

		let postdata = { countryCode: getCountryCode };
		let current = this;


		client.post(api.getEnquiry, postdata, async function (error, response) {
			console.log(response,error)

			if (!error) {

				if (!response.error) {
					
                    set_bookings_record(prev=>
                        {
                            return ({...prev,merchantByCountry: response.tabData,filter_record:response.tabData})
                })
				} 
				else {
					toast.error("Internal error occured. Please contact support");
				}

			} else {
				toast.error("Internal error occured. Please contact support");
			}

		});
	};

  const  fetchCountries = () => {

   
		let current = this;
		client.post(api.fetch_country, { show_all: true }, async function (error, response) {

			if (!error) {
				if (typeof response.error !== "undefined") {
					try {
						if (!response.error) {
							let getCountryCode = [];
							let arrangeCountry = [...response.result.data];
							arrangeCountry = await moveElement(arrangeCountry);
							set_bookings_record(prev=>
                                {
                                    return ({...prev,country: arrangeCountry})
                        })

							arrangeCountry.length > 0 && arrangeCountry.map((countryData) => {
								getCountryCode.push(countryData.country_code)
								return countryData;
							});

							getEnquiry(getCountryCode);
						}
					} catch {
						toast.error("Internal error occured. Please contact support");
					}
				} else {
					toast.error("Internal error occured. Please contact support");
				}
			} else {
				toast.error("Internal error occured. Please contact support");
			}

		});
	}


	useEffect(()=>{
		fetchCountries()
	},[])

	const handleSearchSubmit = (event) => {
		event.preventDefault();
		// fetchType
		// this.getCustomers(this.state.fetchType, this.state.activePage, true);
	};

	function isValidObjectId(str) {
		// Regular expression for a valid 24-character hexadecimal string
		const objectIdPattern = /^[a-f\d]{24}$/i;
		return objectIdPattern.test(str);
	  }

	const onSearchChangeHandler = (event) => {
		
			
			
if(event.target.value)
{

		let filter_record=bookings_record?.merchantByCountry?.filter((elem)=>{
		
			if(elem?.eventType?.toLowerCase()?.includes(event.target.value?.toLowerCase()) || elem?.merch_info?.business_name?.toLowerCase()?.includes(event.target.value?.toLowerCase()) || elem?.merch_info?.venue_name?.toLowerCase()?.includes(event.target.value?.toLowerCase()) || elem?.message?.toLowerCase()?.includes(event.target.value?.toLowerCase()) || elem?.space_data?.space_name?.toLowerCase()?.includes(event.target.value?.toLowerCase())  )
			{
				return true

			}
			else{
				return false
			}
		})

		set_bookings_record(prev=>
			{
				return ({...prev,searchValue: event.target.value,filter_record:filter_record})
	})
}
else{
	set_bookings_record(prev=>
		{
			return ({...prev,searchValue: event.target.value,filter_record:prev.merchantByCountry})
})
}
		 

	};

const rendertable = (venue_bookings) => {

		const { access_level, searchValue,defaultCountryCode,pageSize,activePage } = bookings_record;
		// let customers = [];	
		let data_lengh = 0;
		let fdata_lengh;


		// console.log(bookings_record)

		  let venue_bookings_country=defaultCountryCode=='ALL'?venue_bookings:venue_bookings?.filter(item=>item?.merch_info?.country?.code==defaultCountryCode)

		  fdata_lengh = venue_bookings_country.length;

		  venue_bookings_country=venue_bookings_country?.slice((pageSize) * (activePage - 1),(activePage*pageSize))

		
			
		

		



		return (
			<Fragment>
				{true ?
				<>
				<GenerateInvoiceModal isInvoiceModalOpen={isInvoiceModalOpen} toggleInvoiceModal={toggleInvoiceModal} invoiceDetails={invoiceDetails} getEnquiry={getEnquiry}/>
				
					<div className="container-fluid">
						<div className="row">
							<div className="col-sm-12 col-xl-12 xl-100" style={{ padding: "0" }}>
								<div className="card">
									<div className="card-body" style={{ padding: "1rem" }}>
										<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: '5px' }}>
											<div>
												<form onSubmit={(event) => handleSearchSubmit(event)}>
													<div>
														<input
															style={{
																border: "#dfe8f1 solid 1px",
																borderRadius: "3px",
																padding: "10px",
																fontSize: "0.75rem",
																outlineColor: '#dfe8f1'
															}}
															type="text"
															placeholder="Search"
															value={searchValue}
															onChange={onSearchChangeHandler}
														/>
													</div>
												</form>
											</div>
											

										</div>

										<div className="table-responsive">
											{
												venue_bookings_country?.length>0 && 
												<table className="table table-border-horizontal function_bookings_table" style={{ fontSize: "0.75rem" }}>
													<thead>
            <tr>
                <th style={{width:'4vw'}}>#</th>
                <th >Store</th>
                <th  >Venue</th>
                <th >Event Type/ Guests</th>
                <th style={{width:'10vw'}}>Event Date/Time</th>
                <th >Space</th>
                <th > Last Message</th>
                <th >Status</th>
                <th >Action</th>
            </tr>
        </thead>
        <tbody>
        {
                venue_bookings_country?.map((elem,i)=>{
                    return(
                        
                        <tr>

            
                        <td className="border"  style={{padding:'0.3rem'}}>
                            {((pageSize) * (activePage - 1) ) + (i+1)}
                        </td>
                        <td className="border" style={{padding:'0.3rem',width:'10vw'}}>
                            {elem?.merch_info?.business_name}
                        </td>
                        <td className="border" style={{padding:'0.3rem',width:'10vw'}}>
                            {elem?.merch_info?.venue_name}
                        </td>
                        <td className="border" style={{padding:'0.3rem'}}>
                            {elem?.eventType}/ {elem?.people}
                        </td>
                        <td className="border" style={{padding:'0.3rem'}}>
                           {elem?.date} {elem?.fromTime} - {elem?.toTime}
                        </td>
                        <td className="border" style={{padding:'0.3rem'}}>
                        {elem?.space_data?.space_name}
                        </td>
                        <td className="border" style={{padding:'0.3rem',maxWidth:'14rem'}}>
                        {elem?.message}
                        </td>
                        <td className="border" style={{cursor:'pointer',padding:'0.3rem'}}>
                      {
                       
                        
                           ( elem?.status==1?
                            <span className="d-flex align-items-center">
                              
                                New</span>:
                            elem?.status==2?
                            <span className="d-flex align-items-center">
                             
                                In progress</span>:
                            elem?.status==3?
                            <span className="d-flex align-items-center">
                                
                                Booking confirmed</span>:
                            elem?.status==4?
                            <span className="d-flex align-items-center">
                              {
								elem?.isInvoiceUploaded?
								<a className="inv_btn" href={`https://dmapi.ipaypro.co/images/customer-documents/${elem?.invoiceDocName}`} target="_blank">
									
									View Invoice</a>
									
								:
								<h6 style={{fontSize:'.75rem'}}>Invoice not uploaded by merchant yet</h6>
							  }
                                </span>:
                            elem?.status==5?
                            <span className="d-flex align-items-center">
                                
                                Cancelled</span>:
                                null)
						   }

                              
                                                                                                                        
                        </td>
                        <td className="border"   style={{padding:'0.3rem'}}>
							{
								elem?.isInvoiceUploaded? elem?.invoice_details[0]?.status==0?<Button outline className='generate_invoice' onClick={(e)=>{toggleInvoiceModal()
									setInvoiceDetails(elem)
									e.target.blur()
								}}>Generate invoice</Button>:
								elem?.invoice_details[0]?.status==1?
								<>
								<Link to={`/enquiry-invoice/${elem?._id}`}>
								<DescriptionOutlined style={{color:'#2196f3'}} />
								</Link>
								<CheckCircle style={{color:'#ffeb3b'}}/>
								</>
								:
								elem?.invoice_details[0]?.status==2?
								<>
								
								<Link to={`/enquiry-invoice/${elem?._id}`}>
								<DescriptionOutlined style={{color:'#2196f3'}} />
								</Link>
								<CheckCircle style={{color:'green'}} className="mx-1"/>
								<ThumbDown style={{color:'red',transform:'scaleX(-1)'}}/>
								</>
								:
								'-':
								'-'
							}
						</td>
                       

                    </tr>
                    )
                })
            }
      

            
        </tbody>
												</table>
											}


											<div className="mt-3" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
												{bookings_record?.activePage * bookings_record?.pageSize > fdata_lengh ? (
													<p style={{ textAlign: "right", fontSize: "0.63rem", margin: "0" }}>
														Showing {fdata_lengh>0?(bookings_record.activePage - 1) * bookings_record?.pageSize + 1:0} to {fdata_lengh} of {fdata_lengh}{" "}
														entries
													</p>
												) : (
													<p style={{ textAlign: "right", fontSize: "0.63rem", margin: "0" }}>
														Showing {fdata_lengh>0?(bookings_record.activePage - 1) * bookings_record.pageSize + 1:0} to{" "}
														{bookings_record.activePage * bookings_record.pageSize} of {fdata_lengh} entries
													</p>
												)}
												<PaginationComponent
													totalItems={fdata_lengh}
													pageSize={bookings_record.pageSize}
													onSelect={handlePageChange}
													defaultActivePage={bookings_record?.activePage}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					</>
					:
					<div>
						<div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
							<div className="loader" style={{ display: "flex", alignItems: "center" }}>
								<DisappearedLoading size="medium" color="#514F4E" />
							</div>
						</div>
					</div>
				}
			</Fragment>
		);
	};


    return ( 
		<div className="container-fluid">
        <div className="row">
					<div className="col-lg-7 col-sm-12" style={{ fontSize: "12px" }}>
						<Breadcrumb title={"Function Bookings"} parent={"Home"} />
					</div>

                    <div className="container-fluid">
					<div className="row theme-tab">
						<style>
							{`.theme-tab .tab-title li {padding: 10px 8px;white-space: nowrap;}`}
						</style>

						{
							bookings_record?.country.length > 0 && <Tabs
								className="col-sm-12 merchant_req"
								// defaultIndex={this.state.countryTabIndex}
								style={{
									backgroundColor: "white",
									borderRadius: "3px",
									border: "1px solid rgb(223, 232, 241)",
									borderBottom: "none",
									marginBottom: "0px",
								}}
								onSelect={(index) => {
									set_bookings_record(prev=>
										{
											return ({...prev,countryTabIndex: index})
								})
									 }}
							>

								<TabList className="tabs tab-title">
									<div
										style={{ display: "flex", alignItems: "center", padding: "10px 15px" }}
										onClick={() => changeCountryTab('ALL')}
										className={bookings_record?.defaultCountryCode == "ALL" ? 'selectedTabStyle' : 'tabStyle'}
									>
										All {`(${bookings_record?.filter_record?.length})`}
									</div>
									{
										bookings_record?.country.length > 0 && bookings_record?.country.slice(0, 5).map((countryData) => {
											console.log(countryData)
											let merchantCount = bookings_record?.filter_record.filter((merchCount) => 
												{
return(merchCount?.merch_info?.country?.code==countryData.country_code)
												})?.length
											return (
												<>
													<div
														className={countryData.country_code == bookings_record?.defaultCountryCode ? 'selectedTabStyle' : 'tabStyle'}
														style={{ display: "flex", alignItems: "center", padding: "10px 15px" }}
														onClick={() => changeCountryTab(countryData.country_code)}
													>
														{countryData.country_name} {merchantCount ? `(${merchantCount})` : `(0)`}
														{/* {console.log(merchantByCountry, "merchantByCountry")} */}
													</div>
												</>
											)
										})
									}

									<Dropdown style={{ margin: "auto 0px" }}>
										<Dropdown.Toggle
											id=""
											className={bookings_record?.countryTabIndex == 6 ? 'selectDropDownBtnStyle' : 'dropDownBtnStyle'}
											style={{ color: "#000" }}
										// onClick={() => this.setState({ countryTabIndex: 5 })}
										>
											Others
										</Dropdown.Toggle>

										<Dropdown.Menu>
											<style>
												{`.dropdown-menu.show {min-height:145px;max-height:400px;overflow:auto}`}
											</style>
											{
												bookings_record?.country.length > 5 && bookings_record?.country.slice(5, bookings_record?.country.length).map((countryData) => {
													let merchantCount =bookings_record?.filter_record.filter((merchCount) => 
														{
		return(merchCount?.merch_info?.country?.code==countryData.country_code)
														})?.length
													return (
														<>
															<Dropdown.Item className={countryData.country_code == bookings_record?.defaultCountryCode ? 'selectedDropDownStyle' : "dropDownStyle"} onClick={() => changeCountryTab(countryData.country_code)}>
																{countryData.country_name} {merchantCount ? `(${merchantCount.data})` : `(0)`}
															</Dropdown.Item>
														</>
													)
												})
											}
										</Dropdown.Menu>
									</Dropdown>

									
								</TabList>
							</Tabs>
						}


						{
							rendertable(bookings_record?.filter_record)
						}

						
					</div>
				</div>
					
				</div>
				</div>
     );
}

export default FunctionBookings;