import React, { useRef, useState, useEffect } from "react";
import { Row, Col, Table, Tooltip } from 'reactstrap';
import { toast } from 'react-toastify';
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import axios from 'axios';
import moment from "moment";
import { Delete } from '@material-ui/icons';
import { MdOutlineContentCopy } from "react-icons/md";


const ShortenUrl = () => {

    const [originalUrl, setOriginalUrl] = useState('');
    const [shortUrl, setShortUrl] = useState('');
    const [data, setData] = useState([]);
    const [copyTextToolTip, setCopyTextToolTip] = useState(false);

    const copyTextToolTipToggle = () => { setCopyTextToolTip(!copyTextToolTip) };

    const handleShortenUrl = () => {
        try {
            client.post(api.shortenUrl, {url: originalUrl}, (error, response) => {
                if(!response.error){
                    let url = `https://lit.linky.biz/${response.data._id}`;
                    setShortUrl(url);
                    fetchShortUrl();
                }
            })
        } catch (err) {
            toast.error('Internal error occurred');
        }
    }

    const fetchShortUrl = () => {
        client.get(api.getUrl, {}, (error, response) => {
            if(!response.error){
                setData(response.data)
            }
        })
    }

    const handleDelete = (id) => {
      console.log(id)
      client.post(api.deleteUrl, {id: id}, (error, response) => {
        if(!response.error){
          fetchShortUrl();
          toast.success("Deleted Successfully!")
        }
      })
    }

    useEffect(() => {
       fetchShortUrl();
    }, [])
  
  return (
    <>
      <div>
        <div className="p-4" style={{ background: '#fff' }}>
          <h5 style={{fontSize:'0.9rem',fontWeight:'600'}}>Shorten URL</h5>
          <div className="d-flex">
          <div style={{width:'70%'}}>
            <form>
              <Row className="my-3 mx-0 d-flex align-items-center">
                <Col xs={12} sm={12} md={3}>
                  <div className="" style={{ color: '#000', fontWeight: '500', whiteSpace: 'nowrap', maxWidth: 'fit-content', width: 'fit-content', fontSize: "13px" }}>
                    Enter URL:
                  </div>
                </Col>
                <Col xs={12} sm={12} md={9} style={{ marginTop: "0rem" }}>
                  <div className="d-flex align-items-center" style={{ width: '100%', border: '1px solid grey', borderRadius: '0.2rem', background: '#fff' }}>
                    <input type="text" value={originalUrl} onChange={(e) => setOriginalUrl(e.target.value)} style={{ border: '0', margin: '0.25rem',width:'100%' }} />
                  </div>
                </Col>
              </Row>
              {/* <Row className="my-3 mx-0 d-flex align-items-center">
                <Col xs={12} sm={12} md={3}>
                  <div className="" style={{ color: '#000', fontWeight: '500', whiteSpace: 'nowrap', maxWidth: 'fit-content', width: 'fit-content', fontSize: "13px" }}>
                    Short URL:
                  </div>
                </Col>
                <Col xs={12} sm={12} md={9} style={{ marginTop: "0rem" }}>
                  <div className="d-flex align-items-center" style={{ width: '100%', border: '1px solid grey', borderRadius: '0.2rem', background: '#fff' }}>
                    <input type="text" value={shortUrl} style={{ border: '0', margin: '0.25rem',width:'100%' }} />
                  </div>
                </Col>
              </Row> */}
            </form>
            <div className="d-flex" style={{  }}>
              <button
                style={{
                  border: '0',
                //   width: '50%',
                  color: '#fff',
                  background: '#327ebc',
                  padding: '0.5rem',
                  borderRadius: '0.2rem'
                }}
                onClick={handleShortenUrl}
              >
                Shorten URL
              </button>
            </div>
          </div>
          </div>
        </div>
        <div>
          
          {data && <div className="my-4 py-4">
            URL's:
            <div className="py-4">
              <Table className="table table-border-horizontal" style={{ tableLayout: "fixed", wordWrap: "break-word"  }}>
                <thead>
                  <tr style={{ background: '#f9fafe' }}>
                    <th style={{width: '5%'}}>#</th>
                    <th style={{width: '15%'}}>Date & Time</th>
                    <th style={{width: '55%'}}>Entered URL</th>
                    <th style={{width: '15%'}}>Shorten URL</th>
                    <th style={{width: '10%'}}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    data?.map((item, ind) => {
                        let date = moment(item.createdAt);
                        return (
                            <tr style={{background:'#ffffff'}}>
                                <td>{ind+1}</td>
                                <td>{date.format('DD-MM-YYYY')} {date.format('LT')}</td>
                                <td>{item.main_url}</td>
                                <td>https://lit.linky.biz/{item._id}</td>
                                <td>
                                  <span className="mx-2" style={{ color: 'red', fontSize: '16px' }} onClick={() => handleDelete(item._id)}>
                                  <i className="fa fa-trash"></i>
                                  </span>
                                  <MdOutlineContentCopy id="link_copy" onClick={() => {
                                    navigator.clipboard.writeText(`https://lit.linky.biz/${item._id}`)
                                    copyTextToolTipToggle();
                                  }} />
                                </td>
                                {
                                  copyTextToolTip && (
                                      <Tooltip placement="bottom" toggle={copyTextToolTipToggle} target="link_copy" isOpen={copyTextToolTip}>
                                          Copied
                                      </Tooltip>
                                  )}
                            </tr>
                        )
                    })
                  }
                </tbody>
              </Table>
            </div>
          </div>}
        </div>
      </div>
    </>
  )
}
export default ShortenUrl